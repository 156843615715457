<div class="main">

    <div class="encuestas">
        <div class="title">
            <p>Encuentas asignadas por el profesor</p>
        </div>

        <div class="encuesta" *ngFor="let Lista of Listas" (click) = "OnClick(Lista.descripcion)">
            <p class="sub_title"> {{Lista.titulo}} </p>
            <div class="content">
                <p class="text"> Profesor:  </p>
                <p class="text"> Francisco Figueroa </p>
            </div>
            <div class="content" >
                <p class="text"> Estado:  </p>
                <p class="text" style="color: #7BFF81;" *ngIf="checkStatus( )"> Finalizada </p>
                <p class="text" style="color: #FF7373;" *ngIf="!checkStatus( )"> No Finalizada </p>
            </div>
            <div class="button" (click)="realizarEncuesta( Lista.id_cuestionario )">
                <button class="b_encuesta" *ngIf="checkStatus( )"> Realizar nuevamente </button>
                <button class="b_encuesta" *ngIf="!checkStatus( )"> Comenzar encuesta </button>
            </div>
        </div>

        <div class="footer">
            <p class="text">Número de encuestas puede variar dependiendo del profesor o profesores asignados al alumno</p>
        </div>
    </div>

    <div class="Res">

        <div class="Description">
            <div class="title">
                <p>Descripción de la encuesta </p>
            </div>
            <div class="des_info">
                <p class="text"> {{ Description }} </p>
            </div>
        </div>

        <div class="statistics">
            <div class="title">
                <p>Estadísticas de la encuesta</p>
            </div>
            <div class="tap">
                <button (click)="cambiasGrafica($event)"> <p id="Grafica" class="active" > Gráfica </p> </button>
                <button (click)="cambiasTabla($event)"> <p id="Tabla" > Tabla </p> </button>
            </div>

            <div class="info_grafic">
                <canvas id="MyChart" class=""> {{ chart }} </canvas>
            </div>
            <div class="info_table activePreview">
                <table class="table table-hover">
                    <thead class="table-dark">
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">11</th>
                        <th scope="col">9</th>
                        <th scope="col">7</th>
                        <th scope="col">5</th>
                        <th scope="col">3</th>
                        <th scope="col">1</th>
                        <th scope="col">1</th>
                        <th scope="col">3</th>
                        <th scope="col">5</th>
                        <th scope="col">7</th>
                        <th scope="col">9</th>
                        <th scope="col">11</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody class="">
                      <tr>
        
                        <th scope="row"> Activo </th>
                        <td id="a11"></td>
                        <td id="a9"></td>
                        <td id="a7"></td>
                        <td id="a5"></td>
                        <td id="a3"></td>
                        <td id="a1"></td>
                        <td id="r1"></td>
                        <td id="r3"></td>
                        <td id="r5"></td>
                        <td id="r7"></td>
                        <td id="r9"></td>
                        <td id="r11"></td>
                        <th scope="row"> Reflexivo </th>
        
                      </tr>
                      <tr>
        
                        <th scope="row"> Sensorial </th>
                        <td id="s11"></td>
                        <td id="s9"></td>
                        <td id="s7"></td>
                        <td id="s5"></td>
                        <td id="s3"></td>
                        <td id="s1"></td>
                        <td id="i1"></td>
                        <td id="i3"></td>
                        <td id="i5"></td>
                        <td id="i7"></td>
                        <td id="i9"></td>
                        <td id="i11"></td>
                        <th scope="row"> Intuitivo </th>
        
                      </tr>
                      <tr>
        
                        <th scope="row"> Visual </th>
                        <td id="v11"></td>
                        <td id="v9"></td>
                        <td id="v7"></td>
                        <td id="v5"></td>
                        <td id="v3"></td>
                        <td id="v1"></td>
                        <td id="ve1"></td>
                        <td id="ve3"></td>
                        <td id="ve5"></td>
                        <td id="ve7"></td>
                        <td id="ve9"></td>
                        <td id="ve11"></td>
                        <th scope="row"> Verbal </th>
        
                      </tr>
                      <tr>
        
                        <th scope="row"> Secuencial </th>
                        <td id="se11"></td>
                        <td id="se9"></td>
                        <td id="se7"></td>
                        <td id="se5"></td>
                        <td id="se3"></td>
                        <td id="se1"></td>
                        <td id="g1"></td>
                        <td id="g3"></td>
                        <td id="g5"></td>
                        <td id="g7"></td>
                        <td id="g9"></td>
                        <td id="g11"></td>
                        <th scope="row"> Global </th>
        
                      </tr>
                    </tbody>
                  </table>
            </div>

        </div>

    </div>
</div>